<template>
  <v-dialog v-model="dialog" max-width="700px">
    <template v-slot:activator="{ on }">
      <v-card
        style="border: 1px solid var(--v-primary-base)"
        v-on="on"
        @click="fetchPacks"
      >
        <v-card-title
          class="mb-2 px-0 d-block text-center"
          style="font-size: 16px !important; text-decoration: none !important"
        >
          {{ $tc("pack", 2) }}
        </v-card-title>

        <v-avatar
          color="primary"
          size="60"
          class="gris3--text text-h2 text-uppercase mx-auto"
          style="display: flex"
        >
          P
        </v-avatar>
        <v-card-subtitle></v-card-subtitle>
      </v-card>
    </template>
    <v-card>
      <v-card-title> {{ $tc("pack", 2) }} </v-card-title>
      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <v-row align="center">
          <v-spacer />
          <NewPack @update="fetchPacks" />
        </v-row>
      </v-card-text>
      <v-data-table :headers="headers" :items="packs" class="elevation-1">
        <template v-slot:item.price="{ item }">{{
          $n(item.price, "currency")
        }}</template>
        <template v-slot:item.actions="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-on="on"
                :color="item.status === 'inscription' ? '' : ''"
                v-bind="attrs"
                large
                >mdi-dots-horizontal</v-icon
              >
            </template>
            <v-list>
              <ViewPack :pack="item" />
              <EditPack :pack="item" @update="fetchPacks" />
              <ArchivePack :pack="item" @update="fetchPacks" />
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "PackSubServices",
  components: {
    NewPack: () =>
      import("@/components/user/settings/configuracion/packs/NewPack"),
    EditPack: () =>
      import("@/components/user/settings/configuracion/packs/EditPack"),
    ViewPack: () =>
      import("@/components/user/settings/configuracion/packs/ViewPack"),
    ArchivePack: () =>
      import("@/components/user/settings/configuracion/packs/ArchivePack"),
  },
  data() {
    return {
      dialog: false,
      packs: [],
      editedIndex: -1,
    };
  },
  computed: {
    headers() {
      let headeres = [
        {
          text: this.$t("packs.description"),
          value: "description",
          align: "center",
        },
        {
          text: this.$t("packs.price"),
          value: "price",
          align: "center",
        },
        {
          text: this.$t("actions"),
          sortable: false,
          value: "actions",
          align: "center",
        },
      ];
      return headeres;
    },
  },
  methods: {
    ...mapActions("packs", ["getPacks"]),
    fetchPacks() {
      console.log("FETCH PACKS");
      this.loading = true;
      this.getPacks({
        filters: {},
        pagination: {},
      }).then((packs) => {
        this.packs = packs.data;
        console.log(this.packs);
      });
    },
  },
};
</script>
