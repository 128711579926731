var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"700px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-card',_vm._g({staticStyle:{"border":"1px solid var(--v-primary-base)"},on:{"click":_vm.fetchPacks}},on),[_c('v-card-title',{staticClass:"mb-2 px-0 d-block text-center",staticStyle:{"font-size":"16px !important","text-decoration":"none !important"}},[_vm._v(" "+_vm._s(_vm.$tc("pack", 2))+" ")]),_c('v-avatar',{staticClass:"gris3--text text-h2 text-uppercase mx-auto",staticStyle:{"display":"flex"},attrs:{"color":"primary","size":"60"}},[_vm._v(" P ")]),_c('v-card-subtitle')],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$tc("pack", 2))+" ")]),_c('div',{staticClass:"close"},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-row',{attrs:{"align":"center"}},[_c('v-spacer'),_c('NewPack',{on:{"update":_vm.fetchPacks}})],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.packs},scopedSlots:_vm._u([{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.$n(item.price, "currency")))]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":item.status === 'inscription' ? '' : '',"large":""}},'v-icon',attrs,false),on),[_vm._v("mdi-dots-horizontal")])]}}],null,true)},[_c('v-list',[_c('ViewPack',{attrs:{"pack":item}}),_c('EditPack',{attrs:{"pack":item},on:{"update":_vm.fetchPacks}}),_c('ArchivePack',{attrs:{"pack":item},on:{"update":_vm.fetchPacks}})],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }